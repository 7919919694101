import React, { Fragment, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import { SecondaryTopbar } from '../../../components/common/TopBar';
import Text from '../../../components/common/Text';
import { CloseDefaultIcon } from '../../../assets/icons';
import { StyledSectionContainer } from '../../../components/common/UploadSection/index.styles';
import RowButton, { RowText } from '../../../components/common/RowButton';
import { LargeTag } from '../../../components/common/Tag';
import Image from '../../../components/common/Image';
import FirstNotice from '../../../assets/images/notice_image_0.png';
import { Notices } from '../NoticeList';
import StyledPageContainer from '../../Upload/UploadDefaultStep/index.styles';
import StyledPreservedText from './index.styles';

interface NoticeDetailPropsType {
  noticeDescription: ReactNode;
  text?: ReactNode;
  imageURL?: string;
}

const termsAndConditions = `### 제1조 (목적)
이 약관은 메이저폴리오(이하 ”회사”라 합니다)에서 제공하는 인터넷 관련 서비스(접속 가능한 유•무선 단말기의 종류와 관계없이 회사가 제공하는 이용 가능한 모든 서비스를 의미하며, 이하 “서비스”라 합니다)를 이용함에 있어 회사와 회원의 권리와 의무, 책임사항을 규정함을 목적으로 합니다.

### 제2조(정의)
이 약관에서 사용하는 용어의 정의는 다음과 같으며 정의되지 않은 이 약관 내 용어의 의미는 일반적인 이용관행에 의합니다.

① “사이트”란 회사가 재화 또는 서비스(이하 “상품 등”이라 합니다)를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며 회사가 모바일 환경에서 서비스하는 모바일 웹과 앱을 포함합니다.
② “이용자”란 “메이저폴리오”에 접속하여 이 약관에 따라 “메이저폴리오”가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
③ “회원”이란 사이트에서 정한 소정의 절차를 거쳐 회원가입을 한 자로서, 약관에 따라 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
④ ‘비회원’이라 함은 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
⑤ “아이디(ID)”라 함은 회원의 식별과 서비스의 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 전자우편주소 또는 소셜 서비스 연동을 통해 수집된 전자우편주소를 말합니다.
⑥ “메일 인증”이라 함은 회원이 서비스의 이용을 위하여 제출한 인증번호를 통해 이메일의 진위여부를 확인하는 것을 말합니다.
⑦ “비밀번호(Password)”라 함은 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 사이트에 등록한 문자와 숫자의 조합을 말합니다.
⑧ “포인트”라 함은 마일리지 등과 같이 상품 등을 구매하거나 사이트가 제공하는 서비스를 이용할 때 현금처럼 사용할 수 있는 회사가 임의로 책정 또는 지급, 조정할 수 있는 재산적 가치 없는 서비스상의 가상 데이터로 사이트 전용의 사이버 화폐를 말합니다. 
⑨ “쿠폰”이라 함은 상품 등을 구매하거나 사이트가 제공하는 서비스를 이용할 때 표시된 금액 또는 비율만큼 할인을 받을 수 있는 쿠폰을 말합니다.
⑩ 게시물 : 회원이 서비스를 이용함에 있어, 서비스상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 기타 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크
⑪ 해지 : 서비스 개통 후 회사 또는 회원이 이용계약의 효력을 상실시키는 행위

### 제3조 (약관 등의 명시와 설명 및 개정)
① 회사는 이 약관의 내용을 회원이 쉽게 확인할 수 있도록 회사의 인터넷 홈페이지(www.majorfolio.com)서비스 초기 화면에 이를 게시합니다.
② 회사는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
③ 회사는 회원에게 제공하는 서비스의 품질 향상 기타 필요한 경우, ‘정보통신망 이용촉진 및 정보보호등에 관한 법률(이하 ‘정보보호법’)’, ‘약관의 규제에 관한 법률(이하 ‘약관규제법’)’ 기타 관련 법령을 위배하지 아니하는 범위 내에서 이 약관을 개정할 수 있습니다.
④ 회사가 이 약관을 개정하고자 할 경우, 회사는 개정 약관의 효력발생일을 명시하여 현행 약관과 함께 개정 약관의 효력발생일 30일 전부터 1항의 방법으로 개정약관을 공지합니다. 단, 회원의 권리ㆍ의무에 중대한 영향을 미치는 개정이 아닌 때에는 효력발생일 7일 전부터 이를 공지할 수 있습니다.
⑤ 개정 약관의 효력발생일 이후 회원이 서비스를 이용하는 경우, 회사는 회원이 개정 약관에 동의한 것으로 봅니다. 단, 회원이 개정 약관에 동의하지 아니할 때에는 회원은 회사에 대하여 회원의 탈퇴 기타 이용계약을 해지할 수 있습니다.
⑥ 유료서비스를 이용하는 회원이 이 약관의 개정에 동의하지 아니하여 이용계약을 해지한 때에는 이 약관이 정한 환불정책에 따릅니다.
⑦ 회사는 서비스의 품질 향상, 회원 의사의 충족 기타 필요한 경우, 이 약관과 달리 개별약관을 둘 수 있고, 이 경우 1항 내지 6항을 준용합니다.
⑧ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.

### 제4조(서비스의 제공 및 변경)
① “메이저폴리오”는 다음과 같은 업무를 수행합니다.

  * 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
  * 구매계약이 체결된 재화 또는 용역의 배송 및 권한 설정
  * 기타 “메이저폴리오”이 정하는 업무

② “메이저폴리오”는 재화 또는 용역의 품질 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
③ “메이저폴리오”가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
④ 전항의 경우 “메이저폴리오”는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “메이저폴리오”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

### 제5조(서비스의 중단)
① "메이저폴리오"은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
② "메이저폴리오"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 다만, "메이저폴리오"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "메이저폴리오"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "메이저폴리오"에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, "메이저폴리오"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "메이저폴리오"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.

### 제6조(회원가입)
① 이용자는 "메이저폴리오"이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
② "메이저폴리오"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
  1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "메이저폴리오"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
  2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
  3. 기타 회원으로 등록하는 것이 "메이저폴리오"의 기술상 현저히 지장이 있다고 판단되는 경우
③ 회원가입계약의 성립 시기는 "메이저폴리오"의 승낙이 회원에게 도달한 시점으로 합니다.
④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "메이저폴리오"에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

### 제7조(회원 탈퇴 및 자격 상실 등)
① 회원은 언제든지 탈퇴를 요청할 수 있으며 메이저폴리오는 즉시 회원탈퇴를 처리합니다.
② 회원이 다음 사유에 해당하는 경우, 메이저폴리오는 회원자격을 제한 및 정지시킬 수 있습니다:
  1. 가입 신청 시 허위 내용을 등록한 경우
  2. 메이저폴리오를 이용하여 구입한 재화 등의 대금, 기타 메이저폴리오 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
  3. 다른 사람의 메이저폴리오 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
  4. 메이저폴리오를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
③ 메이저폴리오가 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회원자격을 상실시킬 수 있습니다.
④ 메이저폴리오가 회원자격을 상실시키는 경우 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

### 제8조(회원에 대한 통지)
① 메이저폴리오가 회원에 대한 통지를 하는 경우, 회원이 메이저폴리오와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
② 메이저폴리오는 불특정다수 회원에 대한 통지의 경우 1주일 이상 메이저폴리오 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

### 제9조(구매신청 및 개인정보 제공 동의 등)
① 메이저폴리오 이용자는 메이저폴리오 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 메이저폴리오는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다:
  1. 재화 등의 검색 및 선택
  2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
  3. 약관 내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인
  4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)
  5. 재화등의 구매신청 및 이에 관한 확인 또는 메이저폴리오의 확인에 대한 동의
  6. 결제방법의 선택
② 메이저폴리오가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
③ 메이저폴리오가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

### 제10조 (계약의 성립)
① 메이저폴리오는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다:
  1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
  2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
  3. 기타 구매신청에 승낙하는 것이 메이저폴리오 기술상 현저히 지장이 있다고 판단하는 경우
② 메이저폴리오의 승낙이 제12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
③ 메이저폴리오의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.

### 제11조(지급방법)
메이저폴리오에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음과 같습니다. 단, 메이저폴리오는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다:
  ① 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌 이체
  ② 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
  ③ 온라인 무통장 입금
  ④ 전자화폐에 의한 결제
  ⑤ 수령 시 대금 지급
  ⑥ 마일리지 등 메이저폴리오가 지급한 포인트에 의한 결제
  ⑦ 메이저폴리오와 계약을 맺었거나 메이저폴리오가 인정한 상품권에 의한 결제
  ⑧ 기타 전자적 지급 방법에 의한 대금 지급 등

### 제12조(수신확인통지․구매신청 변경 및 취소)
  ① 메이저폴리오는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
  ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 메이저폴리오는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.

### 제13조(재화 등의 공급)
  ① 메이저폴리오는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문 제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, 메이저폴리오가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 메이저폴리오는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
  ② 메이저폴리오는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 메이저폴리오가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 메이저폴리오가 고의․과실이 없음을 입증한 경우에는 그러하지 않습니다.

### 제14조(환급)
메이저폴리오는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.

### 제15조(청약철회 등)
① 메이저폴리오와 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다:
  1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우 (다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
  2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
  3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
  4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
③ 제2항 제2호 내지 제4호의 경우에 메이저폴리오가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.

### 제16조(청약철회 등의 효과)
① 메이저폴리오는 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 메이저폴리오가 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
②  메이저폴리오는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다.  메이저폴리오는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 메이저폴리오가 부담합니다.
④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 메이저폴리오는 청약철회 시 그 비용을  누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.

### 제17조(개인정보보호)
① 메이저폴리오는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
② 메이저폴리오는은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
③ 메이저폴리오는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
④ 메이저폴리오는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
⑤ 메이저폴리오가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
⑥ 이용자는 언제든지 메이저폴리오가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 메이저폴리오는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 메이저폴리오는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
⑦ 메이저폴리오는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
⑧ 메이저폴리오 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
⑨ 메이저폴리오는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

### 제18조(메이저폴리오의 의무)
① 메이저폴리오는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
② 메이저폴리오는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
③ 메이저폴리오가 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
④ 메이저폴리오는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.

### 제19조(회원의 ID 및 비밀번호에 대한 의무)
① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 메이저폴리오에 통보하고 메이저폴리오의 안내가 있는 경우에는 그에 따라야 합니다.

### 제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안 됩니다.
① 신청 또는 변경시 허위 내용의 등록
② 타인의 정보 도용
③ 메이저폴리오에 게시된 정보의 변경
④ 메이저폴리오가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
⑤ 메이저폴리오 기타 제3자의 저작권 등 지적재산권에 대한 침해
⑥ 메이저폴리오 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 메이저폴리오에 공개 또는 게시하는 행위

### 제21조(연결“몰”과 피연결“몰” 간의 관계)
① 상위“몰”과 하위“몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결“몰”(웹 사이트)이라고 하고 후자를 피연결“몰”(웹사이트)이라고 합니다.
② 연결 “몰”은 피연결 “몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결 메이저폴리오는의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.

### 제22조(저작권의 귀속 및 이용제한)
① 메이저폴리오가 작성한 저작물에 대한 저작권 기타 지적재산권은 메이저폴리오에 귀속합니다.
② 이용자는메이저폴리오를 이용함으로써 얻은 정보 중 메이저폴리오에게 지적재산권이 귀속된 정보를 메이저폴리오의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
③ 메이저폴리오는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

### 제23조(분쟁해결)
① 메이저폴리오는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
② 메이저폴리오는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
③ 메이저폴리오와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

### 제24조(재판권 및 준거법)
① 메이저폴리오와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
② 메이저폴리오와 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.`;

const personalInformation = `### 1. 개인정보 처리방침
개인정보 처리방침은 메이저폴리오(이하 “회사”라 칭함)가 특정한 가입절차를 거친 이용자들만 이용 가능한 폐쇄형 서비스를 제공함에 있어, 개인정보를 어떻게 수집·이용·보관·파기하는지에 대한 정보를 담은 방침을 의미합니다. 개인정보 처리방침은 개인정보보호법 등 국내 개인정보 보호 법령을 모두 준수하고 있습니다. 본 개인정보 처리방침에서 정하지 않은 용어의 정의는 서비스 이용약관을 따릅니다.

### 2. 수집하는 개인정보의 항목
회사는 서비스 제공을 위해 다음 항목 중 최소한의 개인정보를 수집합니다.

  1) 회원가입 시 수집되는 개인정보
  학교, 학과, 카카오톡 회원번호, 입학연도, 닉네임

  2) 별도로 수집되는 개인정보
    1. 학교 인증을 할 경우
      - 학교 이메일
    2. 과제를 업로드 할 경우
      - 이름, 전화번호
    3. 환불 요청을 할 경우
      - 환불 계좌, 환불 사유
    4. 신고를 할 경우
      - 신고일자, 신고사유

  ※ 각 항목 또는 추가적으로 수집이 필요한 개인정보 및 개인정보를 포함한 자료는 이용자 응대 과정과 서비스 내부 알림 수단 등을 통해 별도의 동의 절차를 거쳐 요청·수집될 수 있습니다.
  ※ 서비스 이용 과정에서 기기 정보(유저 에이전트), 이용 기록(최근 본 게시글 자료), 로그 기록(IP 주소, 접속 시간)이 자동으로 수집될 수 있습니다.

### 3. 수집한 개인정보의 처리 목적
수집된 개인정보는 다음의 목적에 한해 이용됩니다.

  1. 가입 및 탈퇴 의사 확인, 회원 식별 및 관리, 학생 확인
  2. 개인정보 및 관심에 기반한 이용자 친화적 서비스 제공 및 기존·신규 시스템 개발·유지·개선
  3. 불법·약관 위반 게시물 게시와 같은 부정행위 방지를 위한 운영 시스템 개발·유지·개선
  4. 회원관리, 서비스 운영 및 유지보수를 위한 통계 자료 도출

### 4. 개인정보의 수집 방법
회사는 맞춤형 서비스를 제공하기 위해서 회원가입 시 필요한 개인정보를 최소한으로 수집하고 있으며 고객 각각의 기호와 필요에 맞는 서비스를 제공하기 위하여 추가 정보 수집 때도 별도 동의를 얻고 있습니다. 선택 정보는 입력하지 않아도 서비스에 제한이 없으며 추가로 고객상담절차, 이벤트나 경품행사, 영업업무 위탁사 또는 제휴 등의 방법으로 수집하고 이동 전화 및 유/무선 인터넷 서비스를 사용할 때 생성 정보 수집 도구를 통한 방법 등으로도 수집합니다.

### 5. 개인정보의 보유 및 이용기간

  1. 메이저폴리오는 법령에 따른 개인정보 보유ㆍ이용기간 또는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유ㆍ이용기간 내에서 개인정보를 처리ㆍ보유한다.
  2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
    - 홈페이지 회원 가입 및 관리 : 재화 또는 서비스 제공에 해당하는 경우는 관련 법령에 의거하여 보유 기간 동안 보관 후 파기하고 단, 다음과 같은 사유에 해당하는 경우 기재된 기한까지 개인정보를 보관 및 이용합니다.
        - 부정이용방지 확인이 필요한 경우 : 회원 탈퇴 후 3개월(90일)까지
        - 관계 법령 위반에 따른 수사 조사 등이 진행중인 경우에는 해당 수사 종료시까지
        - 서비스 이용에 따른 채권 채무관계 잔존시에는 해당 채권 채무관계 정산시까지
    - 개인정보 유효기간제(휴면계정 정책) : 2년 이상 서비스 이용기록이 없는 이용자의 개인정보는 개인정보보호법에 따라 일반 이용자의 개인정보와 분리(휴면계정으로 전환)하여 저장 및 관리됩니다. 메이저폴리오는 휴면계정으로 전환되기 30일 이전, 해당 내용에 대해 이메일 등을 통해 이용자에게 사전 통지를 하며, 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용ㆍ제공하지 않습니다.
    - 회사방침에 의한 정보 보유
        
      ① 보유목적 : 분쟁조정, 고객문의 대응 및 법령 준수 이력 증빙
      
      ② 보유기간
      
      - 자료 등록 및 판매에 관한 기록 보유 기간: 180일
      - 알림톡 및 문자 발송에 대한 기록 보유 기간 : 180일

    - 다만, 다음의 법령에 의한 사유에 해당하는 경우에는 해당 사유 종료시까지

### 6. 정보주체의 권리, 의무 및 행사

  1. 회원은 언제든지 서비스 내부 [내 정보]에서 자신의 개인정보를 조회하거나 수정, 삭제, 탈퇴 할 수 있으며, 메이저폴리오 정보보호팀 이메일([majorfolio.official@gmail.com](mailto:majorfolio.official@gmail.com))을 통해 열람∙정정∙삭제∙처리정지 요구 등의 권리를 행사할 수 있습니다. 이에 대해 회사는 지체없이 조치하겠습니다.
  2. 회원의 개인정보 열람∙정정∙삭제∙처리정지 요구는 법률에 특별한 규정이 있는 등의 경우에는 회사가 거절할 수 있습니다. 회원은 회사의 열람 등의 요구 거절에 대하여 메이저폴리오 정보보호팀으로 이의를 제기할 수 있습니다.
      
      메이저폴리오 정보보호팀 이메일 : majorfolio.official@gmail.com
      
  3. 회사는 회원의 정보주체 권리에 따른 열람∙정정∙삭제∙처리정지의 요구 시, 요구를 한 이용자가 본인이거나 정당한 법정대리인인지 확인합니다.

### 7. 게시물

  1.  회사는 고객의 게시물을 소중하게 생각하며 해당 게시물이 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우에는 그렇지 않습니다.

    - 스팸(spam)성 게시물 (예 : 행운의 편지, 특정사이트에 대한 광고, 다른 사이트로의 유도 광고 및 링크 등)
    - 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글
    - 동의 없는 타인의 신상공개, 회사의 저작권, 혹은 제3자의 지적재산권 등의 권리를 침해하는 내용,기타 게시판 주제와 다른 내용의 게시물
    - 회사는 바람직한 게시판 문화를 활성화하기 위하여 타인의 신상을 동의 없이 공개할 때에는 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.
    - 다른 주제의 게시판으로 이동할 수 있는 내용은 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다. *6)* 그 밖의 경우에는 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.

  2. 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또한 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개 전에 심사숙고 한 후 서비스를 이용해야 합니다.

### 8. 개인정보의 안전성 확보조치

회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

  1. 관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육
  2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 접근통제시스템 설치

### 9. 링크사이트

  1. 회사는 고객께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.
  2. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.

### 10. 의견수렴 및 불만처리

  1. 회사는 고객의 의견을 소중하게 생각하며, 고객은 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.
  2. 회사는 고객과의 원활환 의사소통을 위해 고객센터를 운영하고 있습니다.
  3. 민원은 접수 후 하루 내에 성실하게 답변 드리겠습니다. 다만, 근무시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.
  4. 기타 개인정보 침해에 관한 상담이 필요한 경우에는 한국인터넷진흥원 개인정보침해신고센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.

| 민원처리센터 |  |
| --- | --- |
| 전자우편 | mailto:majorfolio.official@gmail.com |
| 전화번호 | 010-4181-6329 |

[기타 개인정보 침해 신고센터]

| 문의처 | 연락처 | 홈페이지 |
| --- | --- | --- |
| 개인정보침해신고센터 | (국번없이)118 | http://privacy.kisa.or.kr/ |
| 개인정보 분쟁조정위원회 | 1833-6972 | http://kopico.go.kr/ |
| 대검찰청 사이버수사과 | (국번없이)1301, mailto:cid@spo.go.kr | http://spo.go.kr/ |
| 경찰청 사이버수사국 | (국번없이)182 | https://ecrm.cyber.go.kr/td> |

### 11. 개인정보에 관한 책임자 및 서비스

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 회원의 불만처리 및 피해구제, 열람청구 등을 위하여 개인정보 보호책임자를 지정하고 정보보호팀을 운영하고 있습니다.

  1. 개인정보보호 책임자 소속 및 개인정보보호 담당 부서 : 메이저폴리오 정보보호팀
  2. 메이저폴리오 정보보호팀 이메일 : [majorfolio.official@gmail.com](mailto:majorfolio.official@gmail.com)

기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 다음 기관에 문의하시기 바랍니다.

  1. 개인정보 분쟁조정위원회 : [https://www.kopico.go.kr](https://www.kopico.go.kr/)
  2. 개인정보침해신고센터 : (국번없이) 118 ([https://privacy.kisa.or.kr](https://privacy.kisa.or.kr/))
  3. 사이버범죄 신고시스템 : [https://ecrm.police.go.kr](https://ecrm.police.go.kr/)

### 12. 고지의무
현 개인정보처리방침은 2024년 3월 10일에 제정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 '공지'를 통해 알릴 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.
개인정보처리방침 버전번호: v1.0
개인정보처리방침 공고일자: 2024-03-10
개인정보처리방침 시행일자: 2024-03-17

### 13. 기타
이 개인정보 처리방침은 2024년 3월 10일에 개정되었습니다`;

const marketing = `
본 마케팅 정보 수신에 대한 동의를 거부하실 수 있으며, 이 경우 회원가입은 가능하나 일부 서비스 이용 및 각종 광고, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 서비스 제공이 제한될 수 있습니다. `;

const open = `🚀 과제 스트레스에서 해방시켜줄, 단 하나의 서비스 등장!

지난 수업에서 A+ 받았던 선배들 과제가 궁금하시지 않나요?
열심히 잘하고는 싶은데, 방법을 모르겠는 수업이 있으신가요?
내가 밤낮 없이 만든 과제, 한 번 제출하고 끝나는게 아쉽지 않으세요?

저희 Majorfolio가 과제에 대해 고민이 많으실 대학생과 신입생 여러분들을
위하여 오랜 시간 서비스 개발에 고군분투해왔습니다!

🎓  Majorfolio란?
Majorfolio는 선후배 대학생들이 모여 만드는 과제장터 플랫폼으로,
우리 학교, 내 전공, 내 교수님 수업을 들었던 지난 선배들의 과제를
커피 한 잔 값에 구매할 수 있어요!

또한, 반대로 내가 선배가 되어
학기 끝나면 무용지물이 되어버리던 지난 내 과제들을
후배분들에게 판매하고, 그에 대한 보상을 얻을 수 있습니다!

☑  후배들은 검증된 학교 선배들의 자료를 볼수 있고,
☑ 선배들은 지난 과제들에 대한 보상을 받아 보람 있는,
☑ 선후배를 연결하여 동기부여와 성장을 추구하는 서비스
Majorfolio입니다!

여러분들과 함께 성장해나가고 싶습니다.
저희와 지금 같이 시작해보세요!

● Majorfolio 홈페이지 >> https://www.majorfolio.com/home
● Majorfolio 소개 노션 >> https://han.gl/46Un7

🏆 메이저폴리오는 2024 대학 연합 KUIT X STACK 동아리 * 1위 * 서비스로, 이번에 처음 세상에 나옵니다!
🙅  메이저폴리오는 저작권을 중요시하며 시험 족보를 공유/판매하지 않고, 예정도 없습니다.

문의
💬 카카오톡채널 : http://pf.kakao.com/_NexmfG
✉ 메일 : [majorforlio.official@gmail.com](mailto:majorforlio.official@gmail.com)
`;

export default function NoticeDetail({
  noticeDescription,
  text,
  imageURL,
}: NoticeDetailPropsType) {
  const navigate = useNavigate();

  return (
    <>
      <SecondaryTopbar
        transition={
          <button
            type="button"
            onClick={() => navigate(-1)}
            aria-label="previous"
          >
            <CloseDefaultIcon />
          </button>
        }
        title={
          <Text size={18} weight="bold" lineHeight="sm" color="gray/gray900">
            공지사항
          </Text>
        }
      />
      <StyledPageContainer>
        <StyledSectionContainer>
          {noticeDescription}
          {text && <div>{text}</div>}
          {imageURL && (
            <div>
              <Image src={imageURL} alt="notice description" />
            </div>
          )}
        </StyledSectionContainer>
      </StyledPageContainer>
    </>
  );
}

export function NoticeDetailById() {
  const { noticeId: stringNoticeId } = useParams();

  if (!stringNoticeId) {
    return <span />;
  }

  const noticeId = Number(stringNoticeId);

  if (noticeId === Notices.TermsAndConditions) {
    // TODO change HTML tag to satisfy markup semantic
    const noticeDescription = (
      <RowText
        tag={
          <LargeTag
            backgroundColor="gray/gray100"
            size={12}
            weight="bold"
            lineHeight="sm"
            color="gray/gray900"
          >
            공지
          </LargeTag>
        }
        text="서비스 이용약관 안내"
      />
    );

    const text = (
      <StyledPreservedText size={14} lineHeight="lg" color="gray/black">
        <Markdown>{termsAndConditions}</Markdown>
      </StyledPreservedText>
    );

    return <NoticeDetail noticeDescription={noticeDescription} text={text} />;
  }
  if (noticeId === Notices.PersonalInformation) {
    const noticeDescription = (
      <RowText
        tag={
          <LargeTag
            backgroundColor="gray/gray100"
            size={12}
            weight="bold"
            lineHeight="sm"
            color="gray/gray900"
          >
            공지
          </LargeTag>
        }
        text="개인정보 처리방침 안내"
      />
    );

    const text = (
      <StyledPreservedText size={14} lineHeight="lg" color="gray/black">
        <Markdown>{personalInformation}</Markdown>
      </StyledPreservedText>
    );

    return <NoticeDetail noticeDescription={noticeDescription} text={text} />;
  }

  if (noticeId === Notices.Marketing) {
    const noticeDescription = (
      <RowText
        tag={
          <LargeTag
            backgroundColor="gray/gray100"
            size={12}
            weight="bold"
            lineHeight="sm"
            color="gray/gray900"
          >
            공지
          </LargeTag>
        }
        text="마케팅 수신 동의"
      />
    );

    const text = (
      <StyledPreservedText size={14} lineHeight="lg" color="gray/black">
        마케팅 수신 동의
        <br />
        <br />
        <table>
          <tr>
            <th>목적</th>
            <th>항목</th>
            <th>보유기간</th>
          </tr>
          <tr>
            <td>
              메이저폴리오가 제공하는 이용자 맞춤형 서비스 및 상품 추천, 각종
              경품 행사, 이벤트 등의 광고성 정보 제공(이메일, 서신우편, SMS,
              카카오톡 등)
            </td>
            <td>이름, 이메일주소, 휴대전화번호, 마케팅 수신 동의 여부</td>
            <td>회원 탈퇴 후 30일 또는 동의 철회 시까지</td>
          </tr>
        </table>
        {marketing}
      </StyledPreservedText>
    );

    return <NoticeDetail noticeDescription={noticeDescription} text={text} />;
  }

  if (noticeId === Notices.Open) {
    const noticeDescription = (
      <RowText
        tag={
          <LargeTag
            backgroundColor="gray/gray100"
            size={12}
            weight="bold"
            lineHeight="sm"
            color="gray/gray900"
          >
            공지
          </LargeTag>
        }
        text="서비스 오픈 안내"
      />
    );

    const text = (
      <StyledPreservedText size={14} lineHeight="lg" color="gray/black">
        {open}
      </StyledPreservedText>
    );

    return <NoticeDetail noticeDescription={noticeDescription} text={text} />;
  }
}
