import { createGlobalStyle } from 'styled-components';
import theme from '../theme';

const GlobalStyle = createGlobalStyle`

  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  body {
    -ms-overflow-style: none;
    background-color: ${theme.color['gray/grayBG']};
  }
  
  ::-webkit-scrollbar {
      display: none;
  }

  * {
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }

  // TODO shrink the heigh
  button {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    line-height: 1;
    height: fit-content;
  }

  


  .Toastify__toast {
    padding: 0;
    margin: 0 0 8px 0;
    min-height: fit-content;
  }

  .Toastify__toast-body {    
    padding: 0;
    margin: 0;
  }

  :root{
    --toastify-color-dark: ${theme.color['gray/gray500']};
    --toastify-icon-color-success: ${theme.color['sub_color/green/p']};
    --toastify-color-success: ${theme.color['sub_color/green/p']};
    --toastify-spinner-color: ${theme.color['sub_color/green/p']};
    --toasity-toast-min-height: 32px;
    --toastify-toast-bd-radius: 6px;
  }
`;

export default GlobalStyle;
